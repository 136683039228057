// StyleSheets and Bootstrap
/* eslint-disable */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "./static/css/styles.css";

export const onInitialClientRender = () => {
    setTimeout(() => {
        setTimeout(() => {
            document.getElementById("___loader").style.opacity = "0"
        }, 0)
        document.getElementById("___loader").style.visibility = "hidden"
    }, 0)
}